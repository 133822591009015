<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统设置</el-breadcrumb-item>
            <el-breadcrumb-item>系统用户</el-breadcrumb-item>
            <el-breadcrumb-item>修改管理员</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form class="form-container" ref="form" :model="form" label-width="140px">
        <el-form-item label="姓名" :error="$v.form.name.$error ? '请输入姓名' : ''">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="登入帐号" :error="$v.form.account.$error ? '请输入登入帐号' : ''">
          <el-input v-model="form.account"></el-input>
        </el-form-item>
        <el-form-item label="密码" :error="$v.form.pwd.$error ? '请输入密码' : ''">
          <el-input v-model="form.pwd"></el-input>
        </el-form-item>
        <el-form-item label="手机号" :error="$v.form.phone.$error ? '请输入手机号' : ''">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="状态" :error="$v.form.states.$error ? '请选择状态' : ''">

          <el-radio-group v-model="form.states">
            <el-radio :label=1>启用</el-radio>
            <el-radio :label=0>禁用</el-radio>
          </el-radio-group>

        </el-form-item>

        <el-form-item label="用户角色">

          <el-checkbox-group v-model="checkList" @change="typekeychange">
            <el-checkbox v-for="(opt) in rolesdata" :key="opt.id" :label="opt.id">
              {{opt.name}}
            </el-checkbox>
          </el-checkbox-group>

        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getdatilRequest, edituserRequest } from '@/api/admin'
import { getselectlistroleRequest } from '@/api/sys_role'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'EditAdmin',
  data() {
    return {
      form: {
        id: 0,
        name: '',
        account: '',
        pwd: '',
        phone: '',
        states: 0,
        createTime: '',
        lastTime: '',
        lastIp: '',
      },
      rolesdata: [],
      checkList: [],
    }
  },
  created() {
    this.form.id = this.$route.query['id']
    this.getInfo()
  },
  methods: {
    back() {
      this.$router.back()
    },
    typekeychange(e) {
      console.info(e)
      this.checkList = e
    },
    getInfo() {
      getdatilRequest({
        Id: this.form.id,
      }).then((res) => {
        console.log(res)

        this.form = res.data
        this.getselectlistrole()
      })
    },
    getselectlistrole() {
      getselectlistroleRequest().then((res) => {
        this.rolesdata = res.data
        if (this.form.roleids) {
          this.checkList = this.form.roleids.split(',').map(Number);
          console.info(this.checkList);
        }
      })
    },
    save() {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        let params = JSON.parse(JSON.stringify(this.form))
        params.roleids = this.checkList.join(',')

        edituserRequest(params).then((res) => {
          if (res.code == 1) {
            this.$router.go(-1)
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
  },
  validations: {
    form: {
      name: {
        required,
      },
      account: {
        required,
      },
      pwd: {
        required,
      },
      phone: {
        required,
      },
      states: {
        required,
      },
    },
  },
}
</script>

<style lang="less" scoped>
</style>
