import request from '@/utils/request'

// 获取管理员列表
export function getListRequest(params) {
  return request({
    url: '/admin/SysUser/getList',
    method: 'get',
    params
  })
}

// 获取用户详情信息
export function getdatilRequest(params) {
  return request({
    url: '/admin/SysUser/getdatil',
    method: 'get',
    params
  })
}

// 添加用户信息
export function adduserRequest(data) {
  return request({
    url: '/admin/SysUser/adduser',
    method: 'post',
    data
  })
}

// 修改用户信息
export function edituserRequest(data) {
  return request({
    url: '/admin/SysUser/edituser',
    method: 'post',
    data
  })
}

// 删除用户信息
export function deluserRequest(params) {
  return request({
    url: '/admin/SysUser/del',
    method: 'get',
    params
  })
}

// 修改用户状态
export function setStatesRequest(params) {
  return request({
    url: '/admin/SysUser/setStates',
    method: 'get',
    params
  })
}